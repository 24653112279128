import * as React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Link, useIntl } from 'gatsby-plugin-react-intl';
import { GdprQuery } from '../../graphqlTypes';
import Layout from '../components/layout';
import Logo from '../components/logo';
import Navbar from '../components/navbar';
import { NNU } from '../helpers';
import ReactMarkdown from 'react-markdown';
import SEO from '../components/seo';
import SideBar from '../components/sideBar';

import './rgpd.scss';

export default function RGPD() {
  const data = useStaticQuery<GdprQuery>(graphql`
    query GDPR {
      allStrapiGdprs {
        edges {
          node {
            locale
            title
            description
            tag
          }
        }
      }
    }
  `);

  const intl = useIntl();
  const itemData = NNU(data.allStrapiGdprs.edges.find(e => e.node.locale === intl.locale)).node;

  return (
    <Layout
      navBar={layoutScrolledDown => <Navbar scrolled={layoutScrolledDown}>{<h2 className='p-2 px-4'>{itemData.title}</h2>}</Navbar>}
      sideBar={() => (
        <SideBar className='sidebar-secondary-page'>
          <Link to='/'>
            <Logo />
          </Link>
        </SideBar>
      )}
    >
      <SEO title='RGPD' />
      <div className='rgpd-contain'>
        <div className='rgpd-box-contain'>
          <div className='rgpd-heading'>
            <h1 className='rgpd-h1-title'>{itemData.title}</h1>
            <span className='rgpd-tag'>{itemData.tag}</span>
          </div>
          <ReactMarkdown className='description'>{NNU(itemData.description)}</ReactMarkdown>
        </div>
      </div>
    </Layout>
  );
}
